import { data } from 'src/components/Layout/NewHeader/_data'

import {
  MenuProps,
  SubMenuProps,
  ItemsProps,
  TextButtonsProps,
} from 'components/Layout/NewHeader/types'
import { WindowLocation } from '@reach/router'
import { InjectedI18nProps, InjectedTranslateProps } from 'react-i18next'

export function getSearchResults (i18n: InjectedI18nProps & InjectedTranslateProps, location: WindowLocation<unknown>, language: string, search?: string) {
  const params = new URLSearchParams(location.search)
  const searching = params.get('query') || ''

  const findFor = (value: string) =>
    value?.toLowerCase().indexOf(search ?? searching?.toLowerCase()) > -1

  const results = data[language as 'pt']?.map((menu: MenuProps) =>
    menu.subMenu?.map((subMenu: SubMenuProps) =>
      subMenu?.items?.filter((item: ItemsProps) =>
        findFor(item?.title) ||
        findFor(item?.description) ||
        (item.tags && findFor(item?.tags)) ||
        item?.textButtons.find((text: TextButtonsProps) =>
          findFor(text?.title))
      )))

  const hasResult = results.filter((menu: any) => Array.isArray(menu) ? menu?.flatMap(m => m).length > 0 : false)?.length > 0
  return hasResult
}
