import React, { useState, memo } from 'react'
import { useLocation } from '@reach/router'
import { getSearchResults } from 'src/utils/getSearchResults'
import {
  translate,
  InjectedI18nProps,
  InjectedTranslateProps,
} from 'react-i18next'
import { Helmet } from 'react-helmet'

// components
import Layout from 'src/layouts/BaseLayout'
import HeaderFinal from './sections/headerFinal/_index'
import NoResults from './sections/noResults/_index'

// assets
import pageContext from './pageContext.json'

const Search = ({ i18n }: InjectedI18nProps & InjectedTranslateProps) => {
  const [ language ] = useState(i18n.language || 'pt')
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const searching = params.get('query') || ''

  const hasResult = getSearchResults(i18n, location, language)

  return (
    <Layout pageContext={pageContext}>
      <Helmet title={searching} titleTemplate='Buscar por %s | Inter' />
      {hasResult ? (
        <>
          <HeaderFinal
            language={language}
            hasResult={hasResult}
            searching={searching}
          />
        </>
      ) : (
        <NoResults searching={searching} />
      )}
    </Layout>
  )
}

export default translate('Search')(memo(Search))
