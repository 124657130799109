import React from 'react'

import { data } from 'src/components/Layout/NewHeader/_data'
import {
  MenuProps,
  SubMenuProps,
  ItemsProps,
  TextButtonsProps,
} from 'components/Layout/NewHeader/types'

import {
  ContentHeader,
  Title,
  SearchParam,
  CardWrapper,
  Card,
  CardTitle,
  CardLink,
} from './style'

type HeaderProps = {
  hasResult?: boolean;
  searching: string;
  language: 'pt' | 'en';
};

const Header = ({ hasResult, searching, language }: HeaderProps) => {
  return hasResult ? (
    <ContentHeader className='container'>
      <div className='row align-items-center justify-content-between'>
        <div className='col-12'>
          <Title>Encontramos os seguintes resultados para a sua busca.</Title>
          <SearchParam>“{searching}”</SearchParam>
        </div>
      </div>
      <div className='row '>
        {data[language].map((menu: MenuProps) =>
          menu.subMenu?.map((subMenu: SubMenuProps) =>
            subMenu.items
              .filter(
                (item: ItemsProps) =>
                  item.title.toLowerCase().indexOf(searching.toLowerCase()) >
                    -1 ||
                  item?.description
                    ?.toLowerCase()
                    .indexOf(searching?.toLowerCase()) > -1 ||
                  (item.tags &&
                    item?.tags
                      ?.toLowerCase()
                      .indexOf(searching?.toLowerCase()) > -1) ||
                  item?.textButtons.find(
                    (text: TextButtonsProps) =>
                      text?.title
                        ?.toLowerCase()
                        .indexOf(searching?.toLowerCase()) > -1
                  ),
              )
              .map((item: ItemsProps) => (
                <CardWrapper className='col-12 col-md-6' key={item.title}>
                  <Card key={item.title}>
                    <div>
                      <CardTitle>{`${item.parent} > ${item.title}`}</CardTitle>
                    </div>
                    <div className='d-flex flex-column'>
                      {item.textButtons?.map((textButton: TextButtonsProps) => (
                        <CardLink key={textButton.title} href={textButton.link}>
                          {textButton.title}
                        </CardLink>
                      ))}
                    </div>
                  </Card>
                </CardWrapper>
              ))
          ),
        )}
      </div>
    </ContentHeader>
  ) : null
}

export default Header
