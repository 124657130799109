export const data = {
  pt: [
    {
      title: 'O Inter',
      header: 'O Inter',
      subMenu: [
        {
          title: 'O Inter',
          items: [
            {
              title: 'Conheça o Inter',
              parent: 'O Inter',
              icon: 'city-hall',
              description:
                'Inter simples, conta gratuita digital. Seu banco parceiro é assim, conectar soluções em um só lugar no super app!',
              tags: 'Conta digital, gratuita, Plataforma de investimentos, seguros, shopping, crédito, passagens aéreas, Operadora móvel, super app, interbank, banco digital, inter',
              textButtons: [
                {
                  title: 'Saiba mais',
                  link: '/o-inter/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '115px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '99px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '115px',
                },
              },
            },
            {
              title: 'Governança Corporativa',
              parent: 'O Inter',
              icon: 'building',
              description:
                'Entenda como temos adotado as melhores práticas de Governança Corporativa, como forma de agregar valor ao nosso negócio e fortalecer nossa gestão.',
              tags: 'Governança corporativa, melhores práticas, negócios, equidade, regulamentação, ESG, eficiência, cultura, LGPD, estrutura de práticas, valores, transparência, estratégias, análises, adequação, pilares, ética, integridade',
              textButtons: [
                {
                  title: 'Saiba mais',
                  link: '/governanca-corporativa/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '115px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '99px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '115px',
                },
              },
            },
            {
              title: 'ESG',
              parent: 'O Inter',
              icon: 'bycicle',
              description:
                'Sustentabilidade Inter, bom para o planeta e para você. Saiba como o Inter promove ações de sustentabilidade e ecoeficiência para simplificar ainda mais a vida das pessoas.',
              tags: 'sustentabilidade, ESG, relatório anual, coeficiente, autonomia, modelo de negócio, desenvolvimento, ambiental, consumo de energia, inovação, diversidade e igualdade, educação financeira, ética, integridade, resultados',
              textButtons: [
                {
                  title: 'Saiba mais',
                  link: 'https://investors.inter.co/esg/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '115px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '99px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '115px',
                },
              },
            },
            {
              title: 'Correspondente Bancário',
              parent: 'O Inter',
              icon: 'bycicle',
              description:
                'Sustentabilidade Inter, bom para o planeta e para você. Saiba como o Inter promove ações de sustentabilidade e ecoeficiência para simplificar ainda mais a vida das pessoas.',
              tags: 'sustentabilidade, ESG, relatório anual, coeficiente, autonomia, modelo de negócio, desenvolvimento, ambiental, consumo de energia, inovação, diversidade e igualdade, educação financeira, ética, integridade, resultados',
              textButtons: [
                {
                  title: 'Saiba mais',
                  link: '/correspondente-bancario/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '115px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '99px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '115px',
                },
              },
            },
            {
              title: 'Ética e Compliance',
              parent: 'O Inter',
              icon: 'book-open',
              description:
                'Ética é o nosso ponto de partida, código de conduta e ética do Inter, princípios e padrões',
              tags: 'Código de conduta, ética, pilares, Governança e cultura, integridade e controle, comunicação e treinamento, canal de ética, monitoramento, compliance, políticas internas',
              textButtons: [
                {
                  title: 'Conheça as ações',
                  link: '/etica-e-compliance/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '116px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '116px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '115px',
                },
              },
            },
            {
              title: 'Pix',
              parent: 'O Inter',
              icon: 'pix-outline',
              description:
                'Pix é Inter! Pagamento instantâneo sem taxa na sua conta digital',
              tags: 'Chave pix, cashback, transferências gratuitas, dinheiro, conta bancária, telefone, cpf, e-mail, super app, saque, pix troco, compra, pagar, bancos, internet banking, movimentações bancárias',
              textButtons: [
                {
                  title: 'Conheça o Pix',
                  link: '/pix/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '116px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '116px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '115px',
                },
              },
            },
            {
              title: 'Canais de atendimento',
              parent: 'O Inter',
              icon: 'phone',
              description:
                'Na Central de Ajuda você encontra artigos para as perguntas mais frequentes, escritos pela nossa equipe de atendimento.',
              tags: 'setor de atendimento, contato, tellefone, dúvidas, Babi, ouvidoria, sac, fale com a gente, canal de atendimento, cvm, bsm, redes',
              textButtons: [
                {
                  title: 'Conheça nossos canais oficiais de atendimento',
                  link: '/canais-de-atendimento/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '116px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '116px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '115px',
                },
              },
            },
            {
              title: 'Open Finance',
              parent: 'O Inter',
              icon: 'open-banking',
              description:
                'Open Finance é o compartilhamento padronizado de dados e serviços bancários entre as instituições autorizadas pelo Banco Central.',
              tags: 'Open Finance, compartilhamento de serviços bancários, centralizar contas bancárias, instituições financeiras',
              textButtons: [
                {
                  title: 'Saiba mais',
                  link: '/open-finance/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '116px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '116px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '115px',
                },
              },
            },
            {
              title: 'Carreiras',
              parent: 'O Inter',
              icon: 'users',
              description:
                'Vem ser sangue laranja, Inter carreiras, cadastre seu currículo.',
              tags: 'currículo, carreira, trabalhar no inter, vagas, colaboradores',
              textButtons: [
                {
                  title: 'Saiba mais',
                  link: 'https://carreiras.inter.co/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '116px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '99px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '115px',
                },
              },
            },
            {
              title: 'Portabilidade de salário',
              parent: 'O Inter',
              icon: 'salary-portability',
              description:
                'Transfira seu salário para o Inter e aproveite todos os benefícios de uma conta sem taxas, cartão de crédito internacional e dinheiro de volta nas compras.',
              tags: 'portabilidade de salário, benefícios, abrir conta, conta digital gratuita, trazer salário, transferência, folha de pagamento, conta corrente',
              textButtons: [
                {
                  title: 'Saiba mais',
                  link: '/pra-voce/portabilidade-de-salario/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '99px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '99px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '115px',
                },
              },
            },
            {
              title: 'Privacidade de Dados',
              parent: 'O Inter',
              icon: 'security',
              description:
                'Privacidade de segurança dos seus dados pessoais. Direito a privacidade, transparência.',
              tags: 'central de privacidade, privacidade de dados, central de privacidade, boas práticas, prevenção, gestão de riscos, controle de acesso, segurança, monitoramento, Bureau Veritas',
              textButtons: [
                {
                  title: 'Saiba mais',
                  link: '/privacidade-de-dados/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '99px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '99px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '115px',
                },
              },
            },
            {
              title: 'Segurança',
              parent: 'O Inter',
              icon: '',
              description: 'O banco Inter mantem recursos para proteger o Internet Banking, App e e-mails para você aproveitar os serviços do seu banco digital com tranquilidade',
              tags: 'segurança, proteção de dados, transações seguras, i-safe, cuidados com a sua senha, golpes, código de verificação, autenticar transações',
              textButtons: [
                {
                  title: 'Saiba mais',
                  link: '/seguranca/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '99px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '99px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '115px',
                },
              },
            },
            {
              title: 'Negociação',
              parent: 'O Inter',
              icon: '',
              description:
                'Na Central de Ajuda você encontra artigos para as perguntas mais frequentes, escritos pela nossa equipe de atendimento.',
              tags: 'setor de atendimento, contato, tellefone, dúvidas, Babi, ouvidoria, sac, fale com a gente, canal de atendimento, cvm, bsm, redes',
              textButtons: [
                {
                  title: 'Renegociação de dívidas',
                  link: '/negocie/',
                },
                {
                  title: 'Desenrola Brasil',
                  link: '/negocie/desenrola-brasil/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '116px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '116px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '123px',
                },
              },
            },
          ],
        },
      ],
    },
    {
      title: 'Pra você',
      header: 'Pra você',
      subMenu: [
        {
          title: 'Tudo em sua conta',
          items: [
            {
              title: 'Conta Digital',
              parent: 'Pra você',
              icon: 'digital-account',
              description:
                'Abra sua conta gratuita completa, super app para simplicar seu dia a dia. Cartão de crédito sem anuidade e você ganha cashback na fatura. Conta sem tarifas, 100% digital e gratuita.',
              tags: 'conta corrente, banco digital, cartão se anuidade, conta sem tarifas, banco 100% digital, sem agências bancárias, super app, seguros, investimentos, shopping, 400 lojas, cashback, depósito de dinheiro por boleto, pix, TED, 24 horas, saques gratuitos, cartão de débito, dinheiro',
              textButtons: [
                {
                  title: 'Conta Digital',
                  link: '/pra-voce/conta-digital/pessoa-fisica/',
                },
                {
                  title: 'Conta Kids',
                  link: '/pra-voce/conta-digital/conta-kids/',
                },
                {
                  title: 'Inter You',
                  link: '/pra-voce/conta-digital/inter-you/',
                },
                {
                  title: 'Indique e Ganhe',
                  link: '/indique-e-ganhe/',
                },
                {
                  title: 'Inter + Athletico  Paranaense',
                  link: '/athletico-paranaense/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '260px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '307px',
                },
                xl: {
                  w: 'col-xl-2',
                  h: '305px',
                },
              },
            },
            {
              title: 'Inter Invest',
              parent: 'Pra você',
              icon: 'investments',
              description:
                'Investimentos banco Inter, ganhe com sua comunidade de Investimentos. Aumentar a rentabilidade das aplicações. Escolha o tipo de investimento de acordo com seu perfil!  Tenha acesso a análise e projeções sobre economia com relatórios.',
              tags:
                'títulos e valores imobiliários, plataforma de investimentos, fundos de investimentos, distribuição de títulos privado, tesouro direto, custódia de ações, fundo challenger, fundo Inter MRV,  LOG CP, Luggo FII, TBI fundo de investimento, renda fixa crédito, BTSP, MD Firf, FIC FIA, AVM, IFI-E, capital Fic Fim, IFI-D, RLDP, Access Verde, Access Arbor, fundo bandeirantes, Infra Master Fi, Starx, Infra Fic, Ingenious, pictet, vitreo, fim cred priv, Access Constellation, Pmar Firf, PB Ceck, savings, vertex, bdr, L3 invest, lg, IA multi, ações, octopus, inter skopos, JB Investimentos, Mobilitysolutions, Apex, DTVM, FGC, IPO',
              textButtons: [
                {
                  title: 'Meu Porquinho',
                  link: '/pra-voce/investimentos/meu-porquinho/',
                },
                {
                  title: 'CDB',
                  link: '/pra-voce/investimentos/renda-fixa/cdb/',
                },
                {
                  title: 'Tesouro Direto',
                  link: '/pra-voce/investimentos/renda-fixa/tesouro-direto/',
                },
                {
                  title: 'Previdência Privada',
                  link: '/pra-voce/investimentos/previdencia-privada/',
                },
                {
                  title: 'Poupança',
                  link: '/pra-voce/investimentos/poupanca/',
                },
                {
                  title: 'Criptomoedas',
                  link: '/pra-voce/investimentos/criptomoedas/',
                },
                {
                  title: 'Inter DTVM',
                  link: '/inter-dtvm/',
                },
                {
                  title: 'Todos os Investimentos',
                  link: '/pra-voce/investimentos/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '260px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '307px',
                },
                xl: {
                  w: 'col-xl-2',
                  h: '305px',
                },
              },
            },
            {
              title: 'Cartões',
              parent: 'Pra você',
              icon: 'card',
              description:
                'Aqui, seu cartão é sem anuidade, internacional. E você ainda pode ter cashback direto na conta.',
              tags:
                'cartão, Cartões, Cartão de crédito, Cartão Inter, Cartão Inter Black, Cartão de crédito black, Black, Carteira digital, Google Pay, Apple Pay, Samsung Pay, WhatsApp Pay, Facebook Pay, Pagamento por aproximação, Aproximação, Apple Wallet, Mastercard, cashback na fatura, débito sem taxa pra sacar',
              textButtons: [
                {
                  title: 'Cartão de Crédito',
                  link: '/pra-voce/cartoes/',
                },
                {
                  title: 'Programa de Pontos',
                  link: '/pra-voce/cartoes/programa-de-pontos',
                },
                {
                  title: 'Cartão Platinum',
                  link: '/pra-voce/cartoes/cartao-platinum/',
                },
                {
                  title: 'Cartão Black',
                  link: '/pra-voce/cartao-inter-black/',
                },
                {
                  title: 'Aumentar Limite',
                  link: '/pra-voce/cartoes/como-aumentar-limite/',
                },
                {
                  title: 'Cartão INSS',
                  link: '/pra-voce/emprestimos/emprestimo-consignado-publico/cartao-beneficio-inss',
                },
                {
                  title: 'Carteiras Digitais',
                  link: '/pra-voce/cartoes/carteiras-digitais/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '260px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '307px',
                },
                xl: {
                  w: 'col-xl-2',
                  h: '305px',
                },
              },
            },
            {
              title: 'Empréstimos',
              parent: 'Pra você',
              icon: 'credit',
              description:
                'Precisando de dinheiro? Aqui a gente tem várias opções pra te ajudar a conquistar seus objetivos.',
              tags:
                'Home equity, Empréstimo com garantia de imóvel, Empréstimo com garantia, Empréstimo com imóvel em garantia, Empréstimo Consignado, Crédito Consignado, Consignado, Empréstimo para aposentado, Empréstimo para servidor, Portabilidade de consignado com troco, Portabilidade de empréstimo consignado, Saque aniversário FGTS, Adiantamento de FGTS, Antecipação de Saque Aniversário, Adiantamento de Saque Aniversário, Empréstimo FGTS, Consignado, Consignado privado, Consignado para empresa, Empréstimo consignado privado, Cheque especial, O que é cheque especial, como funciona o cheque especial, juros cheque especial, iof cheque especial, qual o prazo para pagar cheque especial',
              textButtons: [
                {
                  title: 'Com Garantia de Imóvel',
                  link: '/pra-voce/emprestimos/home-equity/',
                },
                {
                  title: 'Consignado Público',
                  link: '/pra-voce/emprestimos/emprestimo-consignado-publico/',
                },
                {
                  title: 'Consignado INSS',
                  link: '/pra-voce/emprestimos/emprestimo-consignado-inss/',
                },
                {
                  title: 'Portabilidade de consignado',
                  link: '/pra-voce/emprestimos/portabilidade-consignado/',
                },
                {
                  title: 'Antecipação de FGTS',
                  link: '/pra-voce/emprestimos/antecipacao-fgts/',
                },
                {
                  title: 'Consignado Empresa Privada',
                  link: '/pra-voce/emprestimos/emprestimo-consignado-privado/',
                },
                {
                  title: 'Cheque Especial',
                  link: '/pra-voce/emprestimos/cheque-especial/',
                },
                {
                  title: 'Todas modalidades de crédito',
                  link: '/credito-pessoal-e-credito-imobiliario/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '307px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '307px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '305px',
                },
              },
            },
            {
              title: 'Financiamento',
              parent: 'Pra você',
              icon: 'house-financial',
              description:
                'Facilitamos tudo pra você comprar a sua casa própria ou imóvel comercial.',
              tags:
                'Crédito imobiliário, Financiamento de casa, Financiamento de apartamento, Financiamento de imóvel, Financiamento, Portabilidade de financiamento, Transferência de financiamento, Construcasa, Financiamento de construção, Financiamento de obra, Crédito para construção, Financiamento construção',
              textButtons: [
                {
                  title: 'Financiamento imobiliário',
                  link: '/pra-voce/financiamento-imobiliario/residencial/',
                },
                {
                  title: 'Portabilidade de Financiamento Imobiliário',
                  link:
                    '/pra-voce/financiamento-imobiliario/portabilidade-imobiliario/',
                },
                {
                  title: 'Financiamento para Construção',
                  link: '/pra-voce/financiamento-imobiliario/construcasa/',
                },
                {
                  title: 'Financiamento Imobiliário nos EUA',
                  link: '/pra-voce/financiamento-imobiliario/financiamento-eua/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '307px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '204px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '305px',
                },
              },
            },
            {
              title: 'Seguros',
              parent: 'Pra você',
              icon: 'seguros',
              description:
                'É sempre melhor prevenir. Com nossos seguros, você faz isso de um jeito simples.',
              tags:
                'Seguro, seguros, Seguradora, Seguro celular, Seguro de celular, Seguro para celular, Seguro viagem, Seguro de viagem, Seguro para viagem, Seguro auto, Seguro de carro, Seguro carro, Seguro pet, Assistência pet, Plano pet, Plano de saúde pet, Cartão mais protegido, Seguro cartão mais protegido, Proteção financeira, Seguro cartão, Seguro de vida, Doutor Inter, Dr. Inter, Seguro quebra galho, Seguro quebra-galho, Quebra-galho, Quebra galho, Seguro residencial, Cartão + protegido, Seguro veicular, Seguro moto, Família tranquila, Seguro família, Assistência família, Plano odontológico, Plano odonto, Odonto, Plano dental, Plano dentário, Proteção odonto, Convênio odontológico, Seguro odonto, Dentista, Plano de saúde, Seguro saúde, Seguro de saúde, Saúde, Convênio saúde, Convênio, Seguradora de saúde, Previdência privada, Previdência, Simulador previdência privada, consultas online, médicos, especialidades médicas, agendar consultas, telemedicina',
              textButtons: [
                {
                  title: 'Seguros',
                  link: '/pra-voce/seguros/',
                },
                {
                  title: 'Seguro Pix',
                  link: '/pra-voce/seguros/seguro-pix/',
                },
                {
                  title: 'Plano Odontológico',
                  link: '/pra-voce/seguros/plano-odontologico/',
                },
                {
                  title: 'Planos de saúde',
                  link: '/pra-voce/seguros/plano-de-saude/',
                },
                {
                  title: 'Plano de saúde Pet',
                  link: '/pra-voce/seguros/plano-de-saude-pet/',
                },
                {
                  title: 'Doutor Inter',
                  link: '/pra-voce/seguros/doutor-inter-consulta-online/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '307px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '204px',
                },
                xl: {
                  w: 'col-xl-2',
                  h: '235px',
                },
              },
            },
            {
              title: 'Global Account',
              parent: 'Pra você',
              icon: 'globe',
              description: 'Conta Internacional: abra a sua Global Account Inter, conta em dólar sem taxa de abertura e manutenção. Compra no débito sem IOF',
              tags: 'transferências para contas nos EUA, melhor cotação, compras nos EUA, dolar turismo, dolar comercial, conta digital internacional, usend, exterior, Global account, ACH, Automated Clearing House',
              textButtons: [
                {
                  title: 'Conheça',
                  link: '/pra-voce/global-account/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '129px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '204px',
                },
                xl: {
                  w: 'col-xl-2',
                  h: '235px',
                },
              },
            },
            {
              title: 'Câmbio',
              parent: 'Pra você',
              icon: 'exchange',
              description:
                'Da sua Conta Digital Banco Inter você faz remessas internacionais, pagamentos e transferências em até 130 moedas. E recebe em até 70 moedas. Para a sua empresa: operações desde importações, exportações, aporte de capital, transferências e outras transações em diversas moedas.',
              tags: 'Câmbio, remessas internacionais, transferências para o exterior, capital no exterior, moedas, operações, taxa de câmbio, pagamentos internacional, dolar comercial, mercado de câmbio',
              textButtons: [
                {
                  title: 'Conheça',
                  link: '/cambio/',
                },
                {
                  title: 'Google Adsense',
                  link: '/cambio/google-adsense-pagamento/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '129px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '204px',
                },
                xl: {
                  w: 'col-xl-2',
                  h: '235px ',
                },
              },
            },
            {
              title: 'Consórcio',
              parent: 'Pra você',
              icon: 'payment-notification',
              description:
                'Conquiste sua casa, moto ou carro com o nosso consórcio.',
              tags:
                'Consórcio, Consórcios, Simular consórcio, Simulação de consórcio, Consórcio de carro, Consórcio de veículo, Consórcio auto, Consórcio veículos, Consórcio automóvel, Consórcio de automóvel, Consórcio carro, Consórcio veicular, Consórcio de moto, Consórcio moto, Consórcio imobiliário, Consórcio imóvel, Consórcio de imóvel, Consórcio de imóveis, Consórcio de casa, Consórcio casa,  Consórcio para casa, Consórcio de terreno',
              textButtons: [
                {
                  title: 'Consórcios Inter',
                  link: '/pra-voce/consorcio/',
                },
                {
                  title: 'Consórcio Auto e Moto',
                  link: '/pra-voce/consorcio/auto-moto/',
                },
                {
                  title: 'Imobiliário',
                  link: '/pra-voce/consorcio/imobiliario/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '129px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '135px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '235px',
                },
              },
            },
            {
              title: 'Relacionamento',
              parent: 'Pra você',
              icon: 'heart',
              description:
                'Relacionamento personalizado e completo, conte com seu advisor, seja para tomar decisões, recomendar serviços Inter ou te auxiliar em qualquer assunto no Super App.',
              tags: 'atendimento personalizado, benefícios, Inter One, Inter Black, Win, sem anuidade, seguros viagem, concierge Mastercard, sala Vip aeroportos, wealth Management, planejamento patrimonial, investimentos, dinheiro de volta, cashback, longKey Experience, mastercard surpeenda, priceless Cities, travel Rewards, proteção bagagem, Duo Gourmet',
              textButtons: [
                {
                  title: 'Inter One',
                  link: '/pra-voce/relacionamento/inter-one/',
                },
                {
                  title: 'Inter Black',
                  link: '/pra-voce/relacionamento/inter-black/',
                },
                {
                  title: 'Inter Wealth Management',
                  link: '/pra-voce/relacionamento/inter-win/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '146px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '135px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '235px',
                },
              },
            },
          ],
        },
        {
          title: 'Muito mais que banco',
          items: [
            {
              title: 'Inter Cel',
              parent: 'Pra você',
              icon: 'smartphones',
              description:
                'A maior cobertura e cashback nos planos: na Inter Cel você tem tudo.',
              tags: 'celular, plano, smartphone, celulares, plano de celular',
              textButtons: [
                {
                  title: 'Saiba mais',
                  link: '/intercel/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '85px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '85px',
                },
                xl: {
                  w: 'col-xl-2',
                  h: '93px',
                },
              },
            },
            {
              title: 'Inter Shop',
              parent: 'Pra você',
              icon: 'shopping-bag',
              description:
                'Com o Super App você encontra os produtos que procura num mesmo lugar e ainda ganha Cashback nas compras!',
              tags: 'shopping, cashback, compras, dinheiro de volta',
              textButtons: [
                {
                  title: 'Saiba mais',
                  link: 'https://shopping.inter.co/',
                  description: 'Inter Shop',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '85px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '85px',
                },
                xl: {
                  w: 'col-xl-2',
                  h: '93px',
                },
              },
            },
            {
              title: 'Duo Gourmet',
              parent: 'Pra você',
              icon: 'fork-knife',
              description:
                'Duo Goumert você pede dois pratos e paga apenas um, nos melhores restaurantes do Brasil. Assine  o plano anual e ganhe um cartão Black.',
              tags: 'Duo goumert, cartão Black, restaurantes, assinante, assinatura anual, ganhar, cashback na fatura, sala vip, airportConcierge, programa de benefícios, exclusividade, Travel Rewards, gastronomia, parceiros',
              textButtons: [
                {
                  title: 'Saiba mais',
                  link: '/pra-voce/duo-gourmet/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '85px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '85px',
                },
                xl: {
                  w: 'col-xl-2',
                  h: '93px',
                },
              },
            },
            {
              title: 'Mundo Gamer',
              parent: 'Pra você',
              icon: 'game',
              description:
                'Inter Arcade: Serviço de assinatura de jogos. Uma coleção de games para você se divertir.',
              tags: 'Inter Arcade, jogos, games, 7 dias grátis, assinatura, mais de 1 mil jogos, jogar simultâneamente, entretenimento, diversão',
              textButtons: [
                {
                  title: 'Conheça',
                  link: '/games/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '85px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '85px',
                },
                xl: {
                  w: 'col-xl-2',
                  h: '93px',
                },
              },
            },
            {
              title: 'Viagens',
              parent: 'Pra você',
              icon: 'outbound',
              description:
                'Serviço de viagens do Inter, viaje e receba cashabck. Viajar para onde quiser, agora Inter tem passagens aéreas, hospedagens, aluguel de carros e muito mais vantagens.',
              tags: 'Viagens, cashabck, passgens aéreas, hospedagens, aluguel de carros, reservas, voos nacionais, voos internacionais, hotéis, pousadas, aluguel de carros, bagagens, passagens de ônibus, tarifas de viagens',
              textButtons: [
                {
                  title: 'Saiba mais',
                  link: '/pra-voce/viagens/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '85px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '85px',
                },
                xl: {
                  w: 'col-xl-2',
                  h: '93px',
                },
              },
            },
            {
              title: 'Gift Card',
              parent: 'Pra você',
              icon: 'gift',
              description:
                'Gift Cards Inter para presentear, fácil de usar e economizar. Acerte no presente. É só comprar, enviar o código, e pronto! Quem recebe pode escolher o que comprar',
              tags: 'Gift Card, presente, jogos, para presentear, compra, card, cashback, loja',
              textButtons: [
                {
                  title: 'Saiba mais',
                  link: '/pra-voce/gift-card/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '85px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '85px',
                },
                xl: {
                  w: 'col-xl-2',
                  h: '93px',
                },
              },
            },
            {
              title: 'Recarga',
              parent: 'Pra você',
              icon: 'gift',
              description:
                'Recarga',
              tags: 'Recarga',
              textButtons: [
                {
                  title: 'Saiba mais',
                  link: '/recarga/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '85px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '85px',
                },
                xl: {
                  w: 'col-xl-2',
                  h: '93px',
                },
              },
            },
          ],
        },
      ],
    },
    {
      title: 'Empresas',
      header: 'Tudo o que a sua empresa precisa',
      subMenu: [
        {
          title: 'Tudo o que a sua empresa precisa',
          items: [
            {
              title: 'Conta Digital',
              parent: 'Empresas',
              icon: 'digital-account',
              description:
                'Você faz a gestão financeira da sua empresa pelo App e Internet Banking e não paga nenhuma tarifa.',
              tags:
                'conta pj , pj,  conta digital pj, Digital pj, abrir conta pj , Juridica, Conta empresa, conta pj gratuita ,  conta pj digital , conta cnpj ,  conta juridica ,conta pessoa juridica , conta empresarial, abrir conta juridica , Mei, Microempreendedor individual, Micro empreendedor, Micro empreendedor individual, conta mei, conta pj mei, conta para mei, abrir conta mei, conta mei gratuita',
              textButtons: [
                {
                  title: 'Pessoa Jurídica',
                  link: '/empresas/conta-digital/pessoa-juridica/',
                },
                {
                  title: 'Micro Empreendedor (MEI)',
                  link: '/empresas/conta-digital/mei/',
                },
                {
                  title: 'Conta condomínio',
                  link: '/empresas/conta-digital/conta-condominio/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '248px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '240px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '220px',
                },
              },
            },
            {
              title: 'Cartão de débito e crédito',
              parent: 'Empresas',
              icon: 'card',
              description:
                'Conheça o Inter Mastercard Microbusiness e aproveite o melhor do seu cartão de crédito e débito. Com saques ilimitados e sem pagar nenhuma taxa.',
              tags:
                'cartao de credito pj, cartão de crédito pj, cartão empresarial, cartão de credito para empresa, cartão de crédito empresarial, cartao credito pj, cartao de credito empresarial, cartao de credito para pj, conta pj com cartão de crédito, cartao de crédito pj, cartao de credito empresa, cartao pj, cartão de credito empresarial, cartão de crédito pj online, cartão pj, cartão para empresa, cartão de crédito pessoa jurídica, conta pj cartao de credito, cartão de crédito para empresas, cartao de crédito empresarial, cartao credito empresa',
              textButtons: [
                {
                  title: 'Saiba mais',
                  link: '/empresas/cartao-de-credito-empresarial/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '248px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '240px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '220px',
                },
              },
            },
            {
              title: 'Empréstimos',
              parent: 'Empresas',
              icon: 'credit',
              description:
                'Acelerece seu negócio com nossas linhas de crédito.',
              tags:
                'antecipação de recebíveis, antecipação de recebíveis, antecipação de boletos, antecipacao de recebíveis, antecipação recebíveis, adiantamento de recebíveis, antecipação de recebíveis nota fiscal, antecipação de crédito, antecipacao de boleto, adiantamento de boleto,  emprestimo pj, emprestimo para pj, credito pj, empréstimo para pj, empréstimo pj, emprestimo para empresa, emprestimo para empresas, emprestimos pj, crédito para pj, emprestimo empresarial, emprestimo pessoa jurídica, empréstimo empresarial, credito para pj, empréstimo para empresa, empréstimo para empresas, empréstimos pj, emprestimos para empresas,  equity, home equity, home equity pj, Empréstimo com garantia de imóvel, home equity para empresas, taxas home equity',
              textButtons: [
                {
                  title: 'Antecipação de Recebíveis',
                  link: '/empresas/emprestimos/antecipacao-de-recebiveis/',
                },
                {
                  title: 'Empréstimo Corporativo',
                  link: '/empresas/emprestimos/emprestimo-corporativo/',
                },
                {
                  title: 'Home Equity',
                  link: '/empresas/emprestimos/home-equity/',
                },
                {
                  title: 'Financiamento Imobiliário',
                  link: '/empresas/financiamento-imobiliario/comercial/',
                },
                {
                  title: 'Empréstimo Consignado Privado',
                  link: '/empresas/consignado-privado/',
                },
                {
                  title: 'Capital de Giro',
                  link: '/empresas/emprestimos/capital-de-giro/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '248px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '240px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '220px',
                },
              },
            },
            {
              title: 'Investimentos',
              parent: 'Empresas',
              icon: 'card',
              description:
                'Faça investimentos empresariais no Inter Empresas e rentabilize aquele dinheiro que ficaria parado na conta corrente PJ.',
              tags:
                'investimento, investir, investimentos, cdb, poupança, renda fixa, renda variável, bdr, fundos, fi',
              textButtons: [
                {
                  title: 'Saiba mais',
                  link: '/empresas/investimento-empresarial/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '160px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '240px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '220px',
                },
              },
            },
            {
              title: 'Global Account',
              parent: 'Empresas',
              icon: 'globe',
              description:
                'Sua conta internacional empresarial, segurança e a agilidade de uma plataforma de câmbio',
              tags:
                'Conta internacional nos Estados Unidos, plataforma de cambio, taxa zero de abertura e manutenção, Global Account Business, receba e envie dinheiro, converta seus dólares',
              textButtons: [
                {
                  title: 'Global Account Business',
                  link: '/empresas/global-account-business/',
                },
                {
                  title: 'Câmbio',
                  link: '/cambio/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '160px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '178px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '192px',
                },
              },
            },
            {
              title: 'Serviços',
              parent: 'Empresas',
              icon: 'globe',
              description:
                'Sua conta internacional empresarial, segurança e a agilidade de uma plataforma de câmbio',
              tags:
                'Conta internacional nos Estados Unidos, plataforma de cambio, taxa zero de abertura e manutenção, Global Account Business, receba e envie dinheiro, converta seus dólares',
              textButtons: [
                {
                  title: 'Folha de Pagamento',
                  link: '/empresas/folha-de-pagamento/',
                },
                {
                  title: 'Gestão de cobrança',
                  link: '/empresas/gestao-de-cobranca/',
                },
                {
                  title: 'Integrações',
                  link: '/empresas/integracoes-para-empresas/',
                },
                {
                  title: 'Maquininha de cartão',
                  link: '/empresas/maquininha-de-cartao/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '160px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '178px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '192px',
                },
              },
            },
            {
              title: 'Seguros',
              parent: 'Empresas',
              icon: 'seguros',
              description:
                'Protegemos sua empresa para você cuidar com tranquilidade do seu negócio!',
              tags:
                'seguro pj, seguros para empresas, , seguro prestamista empresarial, seguros corporativos, seguro para empresas, seguro de empresas, empresas de seguro, seguros empresas, seguro empresas, Segurança empresarial, Garantia empresarial',
              textButtons: [
                {
                  title: 'Ver todos os seguros',
                  link: '/empresas/seguros/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '160px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '178px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '192px',
                },
              },
            },
            {
              title: 'Relacionamento',
              parent: 'Empresas',
              icon: 'seguros',
              description:
                'O programa de pontos para empresas que seu negócio precisa, agora seu cartão acumula pontos e sua empresa tem flexibilidade para escolher os benefícios que mais combinam com o seu negócio.',
              tags:
                'programa de pontos para empresas, flexibilidade para escolher os benefícios, comprou ganhou, use quando quiser, como acumular pontos, cartão de crédito Inter Empresas.',
              textButtons: [
                {
                  title: 'Programa de pontos',
                  link: '/empresas/programa-de-pontos-para-empresas/',
                },
                {
                  title: 'Conta PJ Pro',
                  link: '/empresas/relacionamento/pj-pro',
                },
                {
                  title: 'Conta PJ Enterprise',
                  link: '/empresas/relacionamento/pj-enterprise',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '160px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '178px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '192px',
                },
              },
            },
          ],
        },
      ],
    },
    {
      title: 'Conteúdos',
      header: 'Conteúdos',
      subMenu: [
        {
          title: 'Conteúdos',
          items: [
            {
              title: 'Blog',
              parent: 'Conteúdos',
              icon: 'receipt',
              description: 'Conteúdos para educação financeira.',
              tags: 'Viver é Inter, Conta digital, Cuidando do seu dinheiro, mercado, investimentos, empreendedor, simplicar a vida, cashback, viagens, cartões, crédito, débito, global account, salário, portabilidade, shopping, financiamento, imobiliário',
              textButtons: [
                {
                  title: 'Saiba mais',
                  link: 'https://blog.inter.co',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '85px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '85px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '95px',
                },
              },
            },
            {
              title: 'Inter Invest',
              parent: 'Conteúdos',
              icon: '',
              description: 'Inter Invest',
              tags: 'Inter  Invest',
              textButtons: [
                {
                  title: 'Saiba mais',
                  link: 'https://interinvest.inter.co/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '85px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '85px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '95px',
                },
              },
            },
          ],
        },
      ],
    },
    {
      title: 'Portal RI',
      href: 'https://investors.inter.co/',
    },
  ],
  en: [
    {
      title: 'About Us',
      header: 'About Us',
      subMenu: [
        {
          title: 'About Us',
          items: [
            {
              title: 'Get to know Inter',
              parent: 'About Us',
              icon: 'city-hall',
              description:
                'We create what simplifies your life, thinking about what no one else did.',
              textButtons: [
                {
                  title: 'Learn more',
                  link: '/en/o-inter/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '99px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '99px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '85px',
                },
              },
            },
            {
              title: 'Corporate Governance',
              parent: 'About Us',
              icon: 'building',
              description:
                'Understand how Inter guarantees the continuity of its business, adhering to the best national and international corporate governance practices',
              textButtons: [
                {
                  title: 'Learn more',
                  link: '/en/corporate-governance/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '99px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '99px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '85px',
                },
              },
            },
            {
              title: 'Compliance',
              parent: 'About Us',
              icon: 'book-open',
              description:
                'Our Integrity Program outlines guidelines and initiatives with a 100% focus on ensuring ethical behavior, trustworthiness, and a spirit of partnership with all our stakeholders.',
              textButtons: [
                {
                  title: 'Learn more',
                  link: '/en/compliance/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '99px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '99px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '85px',
                },
              },
            },
            {
              title: 'ESG',
              parent: 'About Us',
              icon: 'bycicle',
              description:
                'Guided by the purpose of simplifying people’s lives, we offer a digital and free platform that has eco-efficiency and sustainability at its core.',
              textButtons: [
                {
                  title: 'Learn more',
                  link: 'https://investors.inter.co/en/esg/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '85px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '99px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '85px',
                },
              },
            },
            {
              title: 'Data Privacy',
              parent: 'About Us',
              icon: 'security',
              description:
                'Understand why the privacy and security of your personal data is and always will be very important to us.',
              textButtons: [
                {
                  title: 'Learn more',
                  link: '/en/data-privacy/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-4',
                  h: '85px',
                },
                lg: {
                  w: 'col-lg-3',
                  h: '85px',
                },
                xl: {
                  w: 'col-xl-3',
                  h: '85px',
                },
              },
            },
          ],
        },
      ],
    },
    {
      title: 'For You',
      header: 'For You',
      subMenu: [
        {
          title: 'For You',
          items: [
            {
              title: 'Personal Account',
              parent: 'For You',
              icon: 'digital-account',
              description:
                "Banco Inter's digital checking account is the first account completely free of charges in Brazil.You make all the transations through the App, you get an International Mastercard and can withdraw money in all ATMs from Rede Banco24Horas. Open your account!",
              textButtons: [
                {
                  title: 'Learn more',
                  link: '/en/digital-account/personal/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-6',
                  h: '85px',
                },
                lg: {
                  w: 'col-lg-6',
                  h: '85px',
                },
                xl: {
                  w: 'col-xl-6',
                  h: '85px',
                },
              },
            },
            {
              title: 'Real Estate Financing In The USA',
              parent: 'For You',
              icon: 'house-financial',
              description:
                'Inter&Co will help you finance your property in the United States.',
              textButtons: [
                {
                  title: 'Learn more',
                  link: '/en/for-you/finance-properties/financing-us/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-6',
                  h: '85px',
                },
                lg: {
                  w: 'col-lg-6',
                  h: '85px',
                },
                xl: {
                  w: 'col-xl-6',
                  h: '85px',
                },
              },
            },
          ],
        },
      ],
    },
    {
      title: 'Companies',
      header: 'Companies',
      subMenu: [
        {
          title: 'Companies',
          items: [
            {
              title: 'Corporate Account',
              parent: 'Companies',
              icon: 'digital-account',
              description:
                "In Banco Inter's Corporate Account you make all of your business' financial management via Internet Banking free of charges. Request your invite!",
              textButtons: [
                {
                  title: 'Learn more',
                  link: '/en/digital-account/corporate/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-6',
                  h: '85px',
                },
                lg: {
                  w: 'col-lg-6',
                  h: '85px',
                },
                xl: {
                  w: 'col-xl-6',
                  h: '85px',
                },
              },
            },
            {
              title: 'Microenterprise Account',
              parent: 'Companies',
              icon: 'user-account',
              description:
                "It's the first bank account from Brazil for individual micro entrepreneurs.  Choose a checking account free of charges, 100 % digital and with all services you need.",
              textButtons: [
                {
                  title: 'Learn more',
                  link: '/en/digital-account/mei/',
                },
              ],
              size: {
                md: {
                  w: 'col-md-6',
                  h: '85px',
                },
                lg: {
                  w: 'col-lg-6',
                  h: '85px',
                },
                xl: {
                  w: 'col-xl-6',
                  h: '85px',
                },
              },
            },
          ],
        },
      ],
    },
    {
      title: 'Investor relations',
      href:
        'https://investors.inter.co/en/?_ga=2.52176745.1505710783.1644971897-1630677674.1637070251',
    },
  ],
}
