import React, { useState } from 'react'
import { navigate, useLocation } from '@reach/router'
import { getSearchResults } from 'src/utils/getSearchResults'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerHeader'
import OrangeDS from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import LeftRight from 'inter-frontend-svgs/lib/v2/navigation/arrow-left'

import {
  InjectedI18nProps,
  InjectedTranslateProps,
} from 'react-i18next'

import {
  ContentHeader,
  Tablet,
  BackToHome,
  Title,
  Description,
  Form,
  InputWrapper,
  Input,
} from './style'

type NoResultsProps = {
  searching: string;
  i18n: InjectedI18nProps & InjectedTranslateProps;
}
const NoResults = ({ searching, i18n }: NoResultsProps) => {
  const location = useLocation()
  const [ language ] = useState('pt')

  const [ sendDatalayerHeaderEvent ] = useDataLayer()
  const [ search, setNewSearch ] = useState('')

  const onSubmit = (e: any) => {
    e.preventDefault()
    const hasResult = getSearchResults(i18n, location, language, search)
    navigate(`/search?query=${search}`)
    sendDatalayerHeaderEvent({
      element_action: 'click button',
      element_name: 'Busca',
      c_page: window.location.href,
      element_search: hasResult ? search : `Nenhum resultado encontrado com ${search}`,
    })
  }

  return (
    <ContentHeader className='container'>
      <div className='row'>
        <div className='col-md-4' />
        <div className='col-12 col-md-8 col-lg-7 col-xl-6'>
          <Tablet>
            <BackToHome href='https://inter.co/'>
              <LeftRight width={24} height={24} color='orange--base' />
              Voltar à página inicial
            </BackToHome>
            <Title>Realize sua busca novamente</Title>
            <Description>Nenhum resultado com “{searching}”.</Description>
            <Form onSubmit={onSubmit} className='w-100'>
              <InputWrapper>
                <Input
                  type='text'
                  placeholder='Pesquisar'
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewSearch(event.target.value)}
                  value={search}
                />
                <div>
                  <OrangeDS size='MD' color='#FF7A00' icon='search' />
                </div>
              </InputWrapper>
            </Form>
          </Tablet>
        </div>
        {/* <WeCanHelp>Podemos te ajudar</WeCanHelp> */}
      </div>
      {/* <div className='row'>
        {fakedata.data.map((item: any) => (
          <CardWrapper className='col-12 col-md-6 col-lg-3' key={item.title}>
            <Card key={item.title}>
              <div>
                <OrangeIcon size='MD' color='#161616' icon='receipt' />
                <CardTitle>{item.title}</CardTitle>
                <CardDescription>{item.description}</CardDescription>
              </div>
              <div className='d-flex flex-column'>
                {item.textButtons?.map((textButton: TextButtonsProps) => (
                  <CardLink key={textButton.title} href={textButton.link}>{textButton.title}</CardLink>
                ))}
              </div>
            </Card>
          </CardWrapper>
        ))}
      </div> */}
    </ContentHeader>
  )
}

export default NoResults
